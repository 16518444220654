import {
    DefaultHttpClient,
    HttpRequest,
    HttpResponse,
    HubConnection,
    HubConnectionBuilder,
    ILogger,
} from "@aspnet/signalr";
import { attempt, ReattemptFunc } from "./util/attempt";
import { backoff } from "./util/backoff";
import { setHeader } from "./util/headers";
import { InvocationPool } from "./util/InvocationPool";
import { createLogger } from "@aspnet/signalr/dist/esm/Utils";
import { regionHelper } from "../../features/region";
import { config } from "common/config";

export type ConnectionSubscriber = (connection: HubConnection) => void;

const MEANDU_API_VERSION = 6;
const MEAND_API_VERSION_HEADER = "x-meandu-api-version";
const MEAND_API_QUERY_KEY = "api_version";

const connectionBackoff = () =>
    backoff({
        initialDelay: 2000,
        multiplier: 1.5,
        maxDelay: 30000,
    });

const connectionRetry = () => {
    const backoff = connectionBackoff();

    return (err: any) => {
        if (err.statusCode === 401) throw err;

        return backoff(err);
    };
};

const apiRetry = (init?: RequestInit, reattempt?: ReattemptFunc) => {
    return async (err: any) => {
        if (err.status === 401) {
            throw err;
        }

        if (!reattempt) {
            throw err;
        }

        await reattempt(err);
    };
};

export type ConnectionInitializer = (connection: Promise<HubConnection>) => Promise<boolean>;

class RegionAwareHttpClient extends DefaultHttpClient {
    private readonly region: string;

    constructor(region: string, logger?: ILogger) {
        super(createLogger(logger));
        this.region = region;
    }

    public async send(request: HttpRequest): Promise<HttpResponse> {
        request.headers = { ...request.headers, "x-meandu-region": this.region };
        return super.send(request);
    }
}

export class ServerOrderApi {
    private connection: HubConnection | null = null;
    private connectionPromise: Promise<HubConnection> | null = null;
    private cancelConnection: CancellationPromise | null = null;

    private initializeConnection: ConnectionInitializer | null = null;
    private onConnectionFailed?: (url: string, count: number) => void = undefined;

    private readonly baseUrl: string;
    private hub: string | null = null;

    private subscribers: ConnectionSubscriber[] = [];

    private invocationPool = new InvocationPool();

    private initialReconnectDelay = 0;

    constructor() {
        this.baseUrl = config.VITE_ORDER_API!;
    }

    async connect(hub: string, accessToken: () => string, onConnectionFailed?: (url: string, count: number) => void) {
        await this.disconnect();

        this.onConnectionFailed = onConnectionFailed;

        this.hub = hub;

        this.connection = new HubConnectionBuilder()
            .withUrl(`${this.baseUrl}${hub}`, {
                accessTokenFactory: accessToken,
                httpClient: new RegionAwareHttpClient(regionHelper.region.id),
            })
            .build();

        this.monitorDisconnections(this.connection);

        await this.reconnect();
    }

    setInitializer(initializer: ConnectionInitializer | null) {
        this.initializeConnection = initializer;
    }

    private async reconnect() {
        try {
            this.cancelConnection = new CancellationPromise();

            const onAttemptFailed = (count: number) =>
                this.onConnectionFailed && this.hub && this.onConnectionFailed(this.hub, count);

            this.connectionPromise = attempt(
                async () => {
                    if (this.initialReconnectDelay > 0) {
                        await delay(this.initialReconnectDelay);
                        this.initialReconnectDelay = 0;
                    }

                    await this.connection!.start();
                    return this.connection!;
                },
                connectionRetry(),
                this.cancelConnection.promise,
                onAttemptFailed
            );

            if (this.initializeConnection) {
                if (!(await this.initializeConnection(this.connectionPromise))) {
                    await this.disconnect();
                }
            }

            for (let subscriber of this.subscribers) {
                subscriber(this.connection!);
            }
        } catch (e) {
            this.connection = null;
            throw e;
        }
    }

    private monitorDisconnections(connection: HubConnection) {
        connection.onclose(async () => {
            if (connection === this.connection) {
                await this.reconnect();
            }
        });

        connection.on("reconnect", async (minDelayMs: number, maxDelayMs) => {
            this.initialReconnectDelay = Math.random() * (maxDelayMs - minDelayMs) + minDelayMs;
            await connection.stop();
        });
    }

    private async awaitConnection(): Promise<HubConnection> {
        if (this.connectionPromise) {
            await this.connectionPromise;
        }

        return this.connection!;
    }

    async invoke<R = any>(name: string, ...args: any[]): Promise<R> {
        const connection = await this.awaitConnection();

        return await this.invocationPool.wrap(() => connection.invoke<R>(name, ...args));
    }

    addConnectionChangedHandler(callback: ConnectionSubscriber): void {
        this.subscribers.push(callback);
    }

    addMessageHandler(methodName: string, newMethod: (...args: any[]) => void) {
        this.addConnectionChangedHandler((connection) => connection.on(methodName, newMethod));
    }

    async disconnect() {
        await this.invocationPool.waitForIdle();

        if (this.cancelConnection) {
            this.cancelConnection.cancel();
            this.cancelConnection = null;
        }

        if (this.connection) {
            const connection = this.connection;
            this.connection = null;

            try {
                await connection.stop();
            } catch (e) {
                // We don't need to handle errors here
            } finally {
                this.connectionPromise = null;
            }
        }
    }

    async send(url: string, init?: RequestInit, reattempt?: ReattemptFunc): Promise<Response> {
        const versionedUrl = applyApiVersion(url, await regionHelper.addRegionHeaders(init));

        const action = async () => {
            const response = await fetch(`${this.baseUrl}${versionedUrl}`, init);

            if (response.status === 401) throw response;

            return response;
        };

        const onAttemptFailed = (count: number) => this.onConnectionFailed && this.onConnectionFailed(url, count);

        return attempt(action, apiRetry(init, reattempt), undefined, onAttemptFailed);
    }
}

function applyApiVersion(url: string, init?: RequestInit) {
    if (init) {
        setHeader(init, MEAND_API_VERSION_HEADER, String(MEANDU_API_VERSION));
    }

    // Put the rest into an 'else' once we resolve the issue where Android isn't always sending the header

    const separator = url.indexOf("?") === -1 ? "?" : "&";

    return `${url}${separator}${MEAND_API_QUERY_KEY}=${MEANDU_API_VERSION}`;
}

class CancellationPromise {
    private _promise = new Promise((_, rej) => (this._reject = rej));
    private _reject?: (err: any) => void = undefined;

    private _cancelled: Error | null = null;

    constructor() {
        this._promise = new Promise((_, rej) => {
            if (this._cancelled) {
                rej(this._cancelled);
            } else {
                this._reject = rej;
            }
        });
    }

    get promise() {
        return this._promise;
    }

    cancel() {
        const error = Error("Cancelled");
        if (this._reject) {
            this._reject(error);
        } else {
            this._cancelled = error;
        }
    }
}

const delay = (ms: number) => new Promise<void>((res) => setTimeout(res, ms));
