import * as React from "react";
import "./HelpPage.scss";
import { regionHelper } from "features/region";

export interface Props {
    locationName: string;
}

export class HelpPage extends React.Component<Props> {
    render() {
        const { locationName } = this.props;
        const helpEmail =
            (regionHelper.region.contactHelp && regionHelper.region.contactHelp.email) || "helpme@meandu.com.au";
        const helpPhone =
            (regionHelper.region.contactHelp && regionHelper.region.contactHelp.phone) || "1300 MEANDU (1300 632 638)";
        return (
            <div className="help-page">
                <div className="help-page__content">
                    <h2 className="help-page__content__title">Support</h2>
                    <div className="help-page__content__wrapper">
                        <div className="help-page__content__description">
                            Need some help? Reach out to the me&u support team
                        </div>
                        <div className="help-page__content__description">
                            Email:{" "}
                            <a
                                className="contact-us__email"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`mailto:${helpEmail}?subject=${locationName}: Venue Management Support`}
                            >
                                {helpEmail}
                            </a>
                        </div>
                        <div className="help-page__content__description">Phone: {helpPhone}</div>
                    </div>
                </div>
            </div>
        );
    }
}
