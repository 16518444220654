// Declared in public/config.(env).js to avoid having to modify these scripts at
// deploy time, which messes with the source maps
const globalConfig: { [key: string]: string | undefined } = (window as any).meanduConfig;

export const config: AppConfiguration = {
    ...globalConfig,
} as any;

export interface AppConfiguration {
    VITE_MANAGE_APP_LINK: string;
    VITE_MANAGEMENT_API: string;
    VITE_MENU_DATA_URI: string;
    VITE_AUTH0_CLIENT_ID: string;
    VITE_AUTH0_DOMAIN: string;
    VITE_AUTH0_CALLBACK_URL: string;
    VITE_LEGACY_REGION: string;
    VITE_ORDER_API: string;
    VITE_OPS_API: string;
    VITE_HIDE_WAIT_TIMES: string;
    VITE_ENABLE_OPEN_TAB_REFUNDS: string;
    VITE_SHOW_TAB_ORDERS: string;
    VITE_HOTJAR_SITE_ID: string;
    VITE_SHOW_FORCE_CHARGE: string;
    VITE_ZENDESK_KEY: string;
}
