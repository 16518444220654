import Hotjar from '@hotjar/browser';
import { config } from 'common/config';
import { AppDispatch, AppMiddleware } from "features";
import { getActiveLocation } from "features/location/selectors/getLocationLocale";
import { regionHelper } from "features/region";
import { StaffLoginAction, TypeKeys as StaffLoginTypeKeys } from "features/staffLogin/reducers/staffLogin";
import { StaffLoginAction as EnrollmentAction, TypeKeys as enrollmentTypeKeys } from 'features/enrolment/reducers/enrolment';

const initHotjarPlugin = () => {
    const siteId = config.VITE_HOTJAR_SITE_ID;
    const hotjarVersion = 6;

    const siteIdValue = Number.parseInt(siteId)
    if (!siteId || isNaN(siteIdValue)) return;

    Hotjar.init(siteIdValue, hotjarVersion);
}

const setHotjarIdentity = (staffLogin: StaffLoginAction, locationName: string) => {
    if (Hotjar.isReady() && (staffLogin.type === StaffLoginTypeKeys.SUCCESS || staffLogin.type === StaffLoginTypeKeys.RESTORE)) {
        const { userId, locationId, name, accessLevelName } = staffLogin;
        const region = regionHelper.region.displayName;

        Hotjar.identify(userId, {
            staffName: name,
            locationId,
            locationName,
            region,
            accessLevel: accessLevelName,
            platform: "venman"
        });
    }
}

export const hotjarMiddleware = () => ({ getState }: AppMiddleware) => (next: AppDispatch) => {
    return (action: StaffLoginAction | EnrollmentAction) => {
        next(action); 
        if ((action.type === enrollmentTypeKeys.SUCCESS || action.type === enrollmentTypeKeys.RESTORE)
            && action.result?.can_bypass_staff_login === false 
            && !regionHelper.region.isGdpr) {
            initHotjarPlugin();
        }

        if (action.type === StaffLoginTypeKeys.SUCCESS || action.type === StaffLoginTypeKeys.RESTORE) {
            const location = getActiveLocation(getState());
            setHotjarIdentity(action, location?.displayName ?? "Unknown");
        }
    };
};